import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    // Main user state
    const [user, setUser] = useState(() => {
        const savedUserData = localStorage.getItem('userData');
        return savedUserData ? JSON.parse(savedUserData) : null;
    });

    // Internal user state
    const [internalUser, setInternalUser] = useState(() => {
        const savedInternalUserData = localStorage.getItem('internalUserData');
        return savedInternalUserData ? JSON.parse(savedInternalUserData) : null;
    });

    const navigate = useNavigate();

    const logout = useCallback(() => {
        setUser(null);
        document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.removeItem('userData');
        navigate('/');
    }, [setUser, navigate]);

    const logoutInterno = useCallback(() => {
        setInternalUser(null);
        document.cookie = "internalAuthToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/login-navemar;";
        localStorage.removeItem('internalUserData');
        localStorage.removeItem('activeItem');
        localStorage.removeItem('selectedPage');
        navigate('/login-navemar');
    }, [setInternalUser, navigate]);

    const login = (userData, authToken, expiration) => {
        const userWithToken = {
            ...userData,
            authToken,
            expiration,
        };
        setUser(userWithToken);
        document.cookie = `authToken=${authToken}; expires=${new Date(expiration).toUTCString()}; path=/;`;
    };

    const loginInterno = (userData, internalAuthToken, expiration) => {
        const internalUserWithToken = {
            ...userData,
            internalAuthToken,
            expiration,
        };
        setInternalUser(internalUserWithToken);
        document.cookie = `internalAuthToken=${internalAuthToken}; expires=${new Date(expiration).toUTCString()}; path=/login-navemar;`;
    };

    useEffect(() => {
        if (user) {
            localStorage.setItem('userData', JSON.stringify(user));
        } else {
            localStorage.removeItem('userData');
        }
    }, [user]);

    useEffect(() => {
        if (internalUser) {
            localStorage.setItem('internalUserData', JSON.stringify(internalUser));
        } else {
            localStorage.removeItem('internalUserData');
        }
    }, [internalUser]);

    return (
        <AuthContext.Provider value={{ user, login, logout, internalUser, loginInterno, logoutInterno }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

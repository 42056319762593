import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './styles/Footer.css';
import direccion from '../assets/icons/Iconos_Direccion-2.svg';
import telefono from '../assets/icons/Iconos_Telefono-3.svg';
import ubicacion from '../assets/icons/Iconos_Ubicacion-1.svg';
import linkedin from '../assets/icons/Iconos-LinkedIn-1.svg';
import instagram from '../assets/icons/Iconos-Instagram-2.svg';
import facebook from '../assets/icons/Iconos-Facebook-3.svg';
import config from '../configuration/config';
import { useAuth } from '../AuthContext';

function Footer() {
    const { t } = useTranslation();
    const { user } = useAuth();

    return (
        <footer className="footer border-top-blue">
            <div className="container-fluid">
                <div className="custom-container">
                    <div className="height-space-min"></div>
                    <div className="footer-mobile">
                        <div className="row align-items-center mt-md-5" >
                            <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-0 ">
                                <ul className="list-unstyled">
                                    <li className="mb-4 nav-item fw-bold">
                                        <Link className={`nav-link titulo-footer`} to={user ? "/inicio" : "/"}>{t('footer.inicio')}</Link>
                                    </li>
                                    <li className="mb-4 nav-item">
                                        <Link className={`nav-link`} to="/quienes-somos">{t('footer.quienes_somos')}</Link>
                                    </li>
                                    <li className="mb-4 nav-item">
                                        <Link className={`nav-link`} to="/nuestros-servicios">{t('footer.nuestros_servicios')}</Link>
                                    </li>
                                    <li className="mb-4 nav-item">
                                        <a className={`nav-link`} href="https://navesoftonline.com" rel="noreferrer" target="_blank">{t('footer.navesoft')}</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-0">
                                <ul className="list-unstyled">
                                    <li className="mb-4 nav-item fw-bold">
                                        <Link className={`nav-link titulo-footer`} to="/tramites-y-consultas">{t('footer.tramites')}</Link>
                                    </li>
                                    <li className="mb-4 nav-item">
                                        <Link className={`nav-link`} to="/preguntas-frecuentes">{t('footer.preguntas')}</Link>
                                    </li>
                                    <li className="mb-4 nav-item">
                                        <Link className={`nav-link`} to="/politica-de-tratamiento-de-datos-personales">{t('footer.politicas')}</Link>
                                    </li>
                                    <li className="mb-4 nav-item">
                                        <Link className={`nav-link`} to="/terminos-y-condiciones">{t('footer.terminos')}</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-5 mt-lg-0">
                                <ul className="list-unstyled">
                                    <li className="mb-4 nav-item fw-bold">
                                        <Link className={`nav-link titulo-footer`} to="/contactenos">{t('footer.contacto')}</Link>
                                    </li>
                                    <li className="mb-4 nav-item">
                                        <Link className={`nav-link`} to="/blog">{t('footer.blog')}</Link>
                                    </li>
                                    <li className="mb-4 nav-item">
                                        <a className={`nav-link`} href="https://form.jotform.com/240397288744671" rel="noreferrer" target="_blank">{t('footer.question')}</a>
                                    </li>
                                    <li className="mb-4 nav-item">
                                        <a className={`nav-link`} href="http://sig.rednavemar.com/index.php/es/" rel="noreferrer" target="_blank">{t('footer.signavemar')}</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-5 mt-lg-0">
                                <ul className="list-unstyled">
                                    <li className="d-flex align-items-center">
                                        <img src={direccion} alt="Icono de dirección" className="mb-4 iconos me-3" />
                                        <p>{t('footer.direccion')}</p>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <img src={ubicacion} alt="Icono de ubicación" className="mb-4 iconos me-3" />
                                        <p>{t('footer.ubicacion')}</p>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <img src={telefono} alt="Icono de teléfono" className="mb-4 iconos me-3" />
                                        <p>{t('footer.telefono')}</p>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <a href={config.linkedinLink} target="_blank" rel="noopener noreferrer">
                                            <img src={linkedin} alt="linkedin" className="iconos me-3" />
                                        </a>
                                        <a href={config.instagramLink} target="_blank" rel="noopener noreferrer">
                                            <img src={instagram} alt="instagram" className="iconos me-3" />
                                        </a>
                                        <a href={config.facebookLink} target="_blank" rel="noopener noreferrer">
                                            <img src={facebook} alt="facebook" className="iconos" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="height-space-min"></div>
                <div className="row border-top-blue pt-4" style={{ height: '50px' }}>
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <p className="derechos-footer">{t('footer.texto_inferior')}</p>
                    </div>

                </div>

            </div>
        </footer>
    );
}

export default Footer;

import React, { useState } from 'react';
import './styles/CookiePage.css';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const CookiePage = ({ onAccept, onReject }) => {
  const [activeTab, setActiveTab] = useState('detalles');

  const renderContent = () => {
    switch (activeTab) {
      case 'acerca':
        return (
          <div>
            <p className="semibold-subtitulo"><Trans>cookies.titulo_opcion1</Trans></p>
            <p><Trans>cookies.texto_opcion1</Trans></p>
            <p><Trans i18nKey="cookies.texto_opcion3"components={{ text_blue: <Link className="texto-azul-link" to='/politica-de-tratamiento-de-datos-personales' target="_blank"/>, text_bold: <Link className="texto-azul-link" to='http://sig.rednavemar.com/index.php/es/' rel="noreferrer noopener" target="_blank" /> }}/></p>
          </div>
        );
      case 'detalles':
        return (
          <div>
            <div className="flex-container">
              <p className="semibold-subtitulo"><Trans>cookies.titulo_opcion2</Trans></p>
              <p className="semibold-subtitulo-azul"><Trans>cookies.titulo_opcion2_activos</Trans></p>
            </div>
            <p><Trans>cookies.texto_opcion2</Trans></p>
            <div className="flex-container">
              <p className="semibold-subtitulo"><Trans>cookies.titulo_opcion2_1</Trans></p>
              <div className="switch-cookies">
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" id="checkIdioma" name="checkIdioma" checked disabled/>
                </div>
              </div>
            </div>
            <p><Trans>cookies.texto_opcion2_1</Trans></p>
            <div className="flex-container">
              <p className="semibold-subtitulo"><Trans>cookies.titulo_opcion2_2</Trans></p>
              <div className="switch-cookies">
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" id="checkDatos" name="checkDatos" checked disabled/>
                </div>
              </div>
            </div>
            <p><Trans>cookies.texto_opcion2_2</Trans></p>
            <div className="flex-container">
              <p className="semibold-subtitulo"><Trans>cookies.titulo_opcion2_3</Trans></p>
              <div className="switch-cookies">
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" id="checkSesion" name="checkSesion" checked disabled/>
                </div>
              </div>
            </div>
            <p><Trans>cookies.texto_opcion2_3</Trans></p>
          </div>
        );
      case 'informacion':
        return (
          <div>
            <p><Trans i18nKey="cookies.texto_opcion3"components={{ text_blue: <Link className="texto-azul-link" to='/politica-de-tratamiento-de-datos-personales' target="_blank"/>, text_bold: <Link className="texto-azul-link" to='http://sig.rednavemar.com/index.php/es/' rel="noreferrer noopener" target="_blank" /> }}/></p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="cookie-modal">
      <div className="cookie-modal-content">
        <div className="cookie-modal-tittle">
          <p className="subtitulo m-0"><Trans>cookies.titulo</Trans></p>
        </div>
        <div className="cookie-modal-body">
          <div className="cookie-modal-tabs">
            <button
              className={activeTab === 'acerca' ? 'active' : ''}
              onClick={() => setActiveTab('acerca')}
            >
              <Trans>cookies.opcion1</Trans>
            </button>
            <button
              className={activeTab === 'detalles' ? 'active' : ''}
              onClick={() => setActiveTab('detalles')}
            >
              <Trans>cookies.opcion2</Trans>
            </button>
          </div>
          <div className="cookie-modal-text">
            {renderContent()}
          </div>
        </div>
        <div className="cookie-modal-footer">
          {/*<button type="button" className="btn btn-third" onClick={onReject}>Rechazar</button>*/}
          <button type="button" className="btn btn-primary" onClick={onAccept}><Trans>cookies.boton</Trans></button>
        </div>
      </div>
    </div>
  );
};

export default CookiePage;

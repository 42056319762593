import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import esTranslation from './es/traduccion.json';
import esTranslation_politica from './es/traduccion_politica.json';
import enTranslation from './en/translation.json';
import enTranslation_politica from './en/translation_politics.json';
//import frTranslation from './fr/traduction.json';

const getStoredLanguage = () => {
  const localLanguage = localStorage.getItem('cookieLanguage');
  const cookieLanguage = document.cookie.match(/(^| )cookieLanguage=([^;]+)/);
  return localLanguage || (cookieLanguage ? cookieLanguage[2] : 'es');
};

// Configuración de i18next
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: getStoredLanguage(),
    fallbackLng: 'en',
    debug: false,

    // Recursos de idiomas
    resources: {
      es: {
        translation: {
          ...esTranslation,
          ...esTranslation_politica
        }
      },
      en: {
        translation: {
          ...enTranslation,
          ...enTranslation_politica
        }
      },
      react: {
        useSuspense: false,
      },
      detection: {
        order: ['cookie', 'localStorage', 'navigator'],
        caches: ['cookie'],
      },
      /*fr: {
        translation: frTranslation
      }*/
    }
  });

export default i18n;

import React, { useState } from 'react';
import '../styles/LoginModal.css';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import config from '../../configuration/config';
import Alert from '@mui/material/Alert';
import BeatLoader from "react-spinners/BeatLoader";
import MessageModal from '../MessageModal';

const OlvidoPasswordModal = ({ isOpen, onClose }) => {
    const [nit, setNit] = useState('');
    const [responseMessage, setResponseMessage] = useState();
    const [resetButton, setResetButton] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const navigate = useNavigate();
    const redirectToPage = () => {
        closeModal();
        onClose(); 
        navigate('/');
    };

    const buttons = [
        { label: <Trans>recuperar_clave.boton_inicio</Trans>, onClick: redirectToPage },
    ];

    const handlePasswordReset = async (event) => {
        event.preventDefault();
        setResponseMessage('');
        setResetButton(false);
        try {
            const response = await fetch(`${config.apiUrl}/generar-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ nit })
            });
            const data = await response.json();
            setResponseMessage(data.message);
            setResetButton(true);
            if (response.ok && data.message === "OK") {
                openModal();
            }
        } catch (error) {
            setResponseMessage('Error: ' + error);
            setResetButton(true);
        }
    };

    const handleClose = () => {
        setResponseMessage('');
        setResetButton(true);
        setNit('');
        onClose();
    };

    if (!isOpen) return null;

    return (
        <>
            <div className="modal-overlay">
                <div className="modal-container-clave">
                    <div className="modal-margin">
                        <button className="modal-close" onClick={handleClose}>×</button>
                        <h3 className="modal-title my-4"><Trans>recuperar_clave.titulo</Trans></h3>
                        {responseMessage && <Alert severity="info" onClose={() => { }}>{responseMessage}</Alert>}
                        <form className="reset-form" onSubmit={handlePasswordReset}>
                            <input
                                type="text"
                                className="input-field form-control mb-4"
                                id="ptweb_nit"
                                name="ptweb_nit"
                                placeholder="NIT*"
                                value={nit}
                                onChange={(e) => setNit(e.target.value)}
                                maxLength={12}
                                autoComplete="off"
                                required
                            />
                            {resetButton ? <button type="submit" className="login-button"><Trans>recuperar_clave.boton_aceptar</Trans></button> : <BeatLoader color="#0082c6" size={15} margin={5} />}
                            {resetButton ? <button type="button" onClick={handleClose} className="login-button-cancelar mb-4"><Trans>recuperar_clave.boton_cancelar</Trans></button> : ''}
                        </form>
                    </div>
                </div>
            </div>
            <MessageModal
                show={showModal}
                handleClose={closeModal}
                title={<Trans>recuperar_clave.titulo_alerta</Trans>}
                body={<Trans>recuperar_clave.texto_alerta</Trans>}
                buttons={buttons}
                imageType="check"
            />
        </>
    );
};

export default OlvidoPasswordModal;

import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import logo from '../assets/Logo_Navemar.svg';
import './styles/Menu.css';
import menuIcon from '../assets/icons/Icono-menu-homepage.svg';
import customArrowIcon from '../assets/icons/Icono-flecha-menu.svg';
import { saveAs } from 'file-saver';
import pdfFile from '../components/formatos/DocumentNYK22.zip';
import LoginModal from './pages/LoginModal';
import { useAuth } from '../AuthContext';
import { setCookie, getCookie } from '../cookies';
import Tooltip from '@mui/material/Tooltip';
import config from '../configuration/config';
import iconoLogout from '../assets/icons/icono_logout.svg';

/**
 * Componente de menú que proporciona navegación en la aplicación.
 * Utiliza NavLink para la navegación SPA (Single Page Application) que permite cambiar
 * de página sin recargar el sitio web completo. Además, gestiona el cambio de idioma
 * y el cierre de sesión del usuario.
 *
 * @returns {JSX.Element} JSX del componente Menu.
 */
function Menu() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState('es');
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isMenuTramites, setMenuTramitesOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useAuth();
    const { logout } = useAuth();
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const handleLogout = () => {
        logout();
    };

    const openLogin = () => setIsLoginOpen(true);
    const closeLogin = () => setIsLoginOpen(false);

    const urlCargue = config.carguePoderes;
    const urlConsulta = config.consultaPoderes;

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    const handleKeyDown = (event) => {
        if (event.keyCode === 13 || event.keyCode === 32) {
            toggleMenu();
        }
    };

    /**
     * Cambia el idioma de la aplicación.
     * 
     * @param {string} lng - Código del nuevo idioma a establecer (e.g., 'en', 'es').
     */
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
        setCookie('cookieLanguage', lng, { expires: 30 });
        localStorage.setItem('cookieLanguage', lng);
    };

    const navigate = useNavigate();
    const openPerfil = () => {
        navigate('/perfil');
    };

    const [isMobileOrTabletView, setIsMobileOrTabletView] = useState(false);


    const updateView = () => {
        setIsMobileOrTabletView(window.matchMedia("(max-width: 1024px)").matches);
    };

    useEffect(() => {
        const cookieLanguage = getCookie('cookieLanguage');
        if (cookieLanguage && cookieLanguage !== language) {
            i18n.changeLanguage(cookieLanguage);
            setLanguage(cookieLanguage);
        }
    }, [i18n, language]);


    useEffect(() => {
        updateView();
        window.addEventListener('resize', updateView);

        return () => {
            window.removeEventListener('resize', updateView);
        };
    }, []);

    const dropdownRef = useRef(null);

    useEffect(() => {
        if (dropdownRef.current) {
            new Dropdown(dropdownRef.current);
        }
    }, []);

    const servicios = [
        { titulo: t('menu.servicio_agenciamiento'), linkto: '/agenciamiento-maritimo-comercial-y-portuario', orden: 1 },
        { titulo: t('menu.servicio_aerolinea'), linkto: '/representacion-de-aerolineas', orden: 2 },
        { titulo: t('menu.servicio_tecnologia'), linkto: '/soluciones-de-tecnologia', orden: 3 },
        { titulo: t('menu.servicio_bpo'), linkto: '/gestion-de-liberacion-facturacion-y-recaudo-de-gastos-locales', orden: 4 },
    ]

    const serviciosOrdenados = servicios.sort((a, b) => a.orden - b.orden);

    const renderMenu = () => {
        return (
            <ul className={`dropdown-menu ${isMenuOpen ? 'show' : ''} `} aria-labelledby="navbarDropdownServices">
                {serviciosOrdenados.map((item, index) => (
                    <li key={index}><NavLink className="dropdown-item" to={item.linkto}>{item.titulo}</NavLink></li>
                ))}
            </ul>

        );
    };

    const handleDownload = () => {
        saveAs(pdfFile, 'DocumentNYK24.zip');
    };

    const submitFormPoder = (url, tipo) => {

        const form = document.getElementById('f_cargue');
        form.action = url;
        form.target = '_blank';
        if (tipo === "1") {
            const input = document.getElementById('LINEAHAPPA');
            const input2 = document.getElementById('LINEAEVER');
            const input3 = document.getElementById('LINEANYK');
            input.value = "LINEAHAPA";
            input2.value = "";
            input3.value = "";
        } else if (tipo === "2") {
            const input = document.getElementById('LINEAEVER');
            const input2 = document.getElementById('LINEAHAPPA');
            const input3 = document.getElementById('LINEANYK');
            input.value = input.name;
            input2.value = "";
            input3.value = "";
        } else if (tipo === "3") {
            const input = document.getElementById('LINEANYK');
            const input2 = document.getElementById('LINEAEVER');
            const input3 = document.getElementById('LINEAHAPPA');
            input.value = "LINEANAVE";
            input2.value = "";
            input3.value = "";
        }
        else if (tipo === "4") {
            const input = document.getElementById('LINEANYK');
            const input2 = document.getElementById('LINEAEVER');
            const input3 = document.getElementById('LINEAHAPPA');
            input.value = "";
            input2.value = "";
            input3.value = "";
        }

        form.submit();
    };

    const openPagosMenu = () => {
        const form = document.getElementById('f_pagos');
        if (form) {
            form.target = '_blank';
            const nit = document.getElementById('PTWEB_NIT');
            const nitsistema = document.getElementById('PTWEB_NITSISTEMA');
            if (nit && nitsistema) {
                nit.value = user.nit;
                nitsistema.value = user.nitsistema;
                form.submit();
            }
        }
    };

    const handlePagosMenu = (event) => {
        event.preventDefault();
        if (user) {
            openPagosMenu();
        } else {
            openLogin();
        }
    };

    const handleItemClickCargue = (tipo) => {
        submitFormPoder(urlCargue, tipo);
    };
    const handleItemClickConsulta = (tipo) => {
        submitFormPoder(urlConsulta, tipo);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light custom-navbar menu-fijo" style={{ paddingTop: '0', paddingBottom: '0' }}>
            <div className="container-fluid" style={{ marginLeft: '5%', marginRight: '5%', paddingTopTop: '0' }}>
                <NavLink className="navbar-brand" to={user ? "/inicio" : "/"}>
                    <img src={logo} alt="Logo Navemar" className="logo_navemar" />
                </NavLink>
                <div
                    className={`navbar-toggler ${isOpen ? 'active' : ''}`}
                    role="button"
                    tabIndex="0"
                    onClick={toggleMenu}
                    onKeyDown={handleKeyDown}
                    aria-controls="navbarSupportedContent"
                    aria-expanded={isOpen ? 'true' : 'false'}
                    aria-label="Toggle navigation"
                    style={{ border: 'none' }}
                >
                    <img
                        src={menuIcon}
                        alt="Menu Icon"
                        className="icono-menu"
                    />
                </div>
                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" to={user ? "/inicio" : "/"} exact="true">{t('menu.home')}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/quienes-somos">{t('menu.about')}</NavLink>
                        </li>

                        <li className="nav-item dropdown"
                            onClick={() => isMobileOrTabletView && setMenuOpen(!isMenuOpen)}
                            onMouseEnter={() => !isMobileOrTabletView && setMenuOpen(true)}
                            onMouseLeave={() => !isMobileOrTabletView && setMenuOpen(false)}>

                            <NavLink
                                className={`nav-link dropdown-toggle d-flex align-items-center justify-content-between ${isMenuOpen ? 'active' : ''}`}
                                to="/nuestros-servicios"
                                id="navbarDropdownServices"
                                role="button"
                                aria-expanded={isMenuOpen ? 'true' : 'false'}
                                onClick={() => setMenuOpen(!isMenuOpen)}

                            >
                                <span>{t('menu.services')}</span>
                                <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                            </NavLink>
                            {renderMenu()}
                        </li>

                        <li className="nav-item dropdown"
                            onClick={() => isMobileOrTabletView && setMenuTramitesOpen(!isMenuOpen)}
                            onMouseEnter={() => !isMobileOrTabletView && setMenuTramitesOpen(true)}
                            onMouseLeave={() => !isMobileOrTabletView && setMenuTramitesOpen(false)}>
                            <NavLink
                                className={`nav-link dropdown-toggle d-flex align-items-center justify-content-between ${isMenuTramites ? 'active' : ''}`}
                                to="/tramites-y-consultas"
                                id="navbarDropdownTramites"
                                role="button"
                                aria-expanded={isMenuTramites ? 'true' : 'false'}
                                onClick={() => setMenuOpen(!isMenuTramites)}

                            >
                                <span>{t('menu.transaccion')}</span>
                                <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu" />
                            </NavLink>
                            <ul className={`dropdown-menu ${isMenuTramites ? 'show' : ''}`} aria-labelledby="navbarDropdownTransactions">
                                <li className="dropdown-submenu">

                                    <Link className="dropdown-item dropdown-toggle d-flex align-items-center justify-content-between" to="#">
                                        <span>{t('menu.transaccion_pagos')}</span>
                                        <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-submenu" />
                                    </Link>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="https://navesoftonline.com/serviciosenlinea.html" rel="noreferrer noopener" target="_blank">{t('submenu.pagos_hapag')}</a></li>
                                        <li><a className="dropdown-item" href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER" rel="noreferrer noopener" target="_blank">{t('submenu.pagos_evergreen')}</a></li>
                                        <li><Link className="dropdown-item" to="#" onClick={handlePagosMenu}>{t('submenu.pagos_nyk')}</Link></li>
                                    </ul>
                                </li>
                                <li className="dropdown-submenu">
                                    <Link className="dropdown-item dropdown-toggle d-flex align-items-center justify-content-between" to="#">
                                        <span>{t('menu.transaccion_itinerarios')}</span>
                                        <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-submenu" />
                                    </Link>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="https://www.hapag-lloyd.com/es/online-business/schedule/schedule-download-solution.html" rel="noreferrer noopener" target="_blank">{t('submenu.itinerarios_hapag')}</a></li>
                                        <li><a className="dropdown-item" href="https://www.shipmentlink.com/co/" rel="noreferrer noopener" target="_blank">{t('submenu.itinerarios_ever')}</a></li>
                                        {/*<li><a className="dropdown-item" href="https://www.nykroro.com/customer/schedules/" rel="noreferrer noopener" target="_blank">{t('submenu.itinerarios_nyk')}</a></li>*/}
                                        <li><NavLink className="dropdown-item" to="/itinerario-nyk" exact="true">{t('submenu.itinerarios_nyk')}</NavLink></li>
                                    </ul>
                                </li>
                                <li className="dropdown-submenu">
                                    <Link className="dropdown-item dropdown-toggle d-flex align-items-center justify-content-between" to="#">
                                        <span>{t('menu.transaccion_formatos')}</span>
                                        <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-submenu" />
                                    </Link>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="https://navesoftonline.com/serviciosenlinea.html" rel="noreferrer noopener" target="_blank">{t('submenu.formato_hapag')}</a></li>
                                        <li><a className="dropdown-item" href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER?PSCREENNAME=Descargue Formatos" rel="noreferrer noopener" target="_blank">{t('submenu.formato_evergreen')}</a></li>
                                        <li><NavLink className="dropdown-item" to="" onClick={handleDownload}>{t('submenu.formato_nyk')}</NavLink></li>
                                    </ul>
                                </li>
                                <li className="dropdown-submenu">
                                    <Link className="dropdown-item dropdown-toggle d-flex align-items-center justify-content-between" to="#">
                                        <span>{t('menu.transaccion_poderes')}</span>
                                        <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-submenu" />
                                    </Link>
                                    <ul className="dropdown-menu">
                                        <li className="dropdown-submenu-2">
                                            <Link className="dropdown-item dropdown-toggle d-flex align-items-center justify-content-between" to="#">
                                                <span>{t('submenu.poderes_cargue')}</span>
                                                <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-submenu" />
                                            </Link>
                                            <ul className="dropdown-menu">
                                                <li><Link className="dropdown-item" to="" onClick={() => handleItemClickCargue("1")}>{t('submenu.poderes_cargue_hapag')}</Link></li>
                                                <li><Link className="dropdown-item" to="" onClick={() => handleItemClickCargue("2")}>{t('submenu.poderes_cargue_evergreen')}</Link></li>
                                                <li><Link className="dropdown-item" to="" onClick={() => handleItemClickCargue("3")}>{t('submenu.poderes_cargue_nyk')}</Link></li>
                                                <li><Link className="dropdown-item" to="" onClick={() => handleItemClickCargue("4")}>{t('submenu.poderes_cargue_admin')}</Link></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown-submenu-2">
                                            <Link className="dropdown-item dropdown-toggle d-flex align-items-center justify-content-between" to="#">
                                                <span>{t('submenu.poderes_consulta')}</span>
                                                <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-submenu" />
                                            </Link>
                                            <ul className="dropdown-menu">
                                                <li><Link className="dropdown-item" to="" onClick={() => handleItemClickConsulta("1")}>{t('submenu.poderes_consulta_hapag')}</Link></li>
                                                <li><Link className="dropdown-item" to="" onClick={() => handleItemClickConsulta("2")}>{t('submenu.poderes_consulta_evergreen')}</Link></li>
                                                <li><Link className="dropdown-item" to="" onClick={() => handleItemClickConsulta("3")}>{t('submenu.poderes_consulta_nyk')}</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className="dropdown-submenu">
                                    <Link className="dropdown-item dropdown-toggle d-flex align-items-center justify-content-between" to="/tramites-y-consultas#buques">
                                        <span>{t('menu.transaccion_buques')}</span>
                                    </Link>
                                </li>
                                <li className="dropdown-submenu">
                                    <Link className="dropdown-item dropdown-toggle d-flex align-items-center justify-content-between" to="#">
                                        <span>{t('menu.transaccion_saes')}</span>
                                        <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-submenu" />
                                    </Link>
                                    <ul className="dropdown-menu">
                                        <li className="dropdown-submenu-2">
                                            <Link className="dropdown-item dropdown-toggle d-flex align-items-center justify-content-between" to="#">
                                                <span>{t('submenu.saes_cargue')}</span>
                                                <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-submenu" />
                                            </Link>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="https://formsweb.navesoft.com/ords_halocol_croma/web_carga_archivos" rel="noreferrer noopener" target="_blank">{t('submenu.saes_cargue_hapag')}</a></li>
                                                <li><a className="dropdown-item" href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER?PSCREENNAME=Documentar mi embarque" rel="noreferrer noopener" target="_blank">{t('submenu.saes_cargue_evergreen')}</a></li>
                                            </ul>
                                        </li>
                                        {/*<li className="dropdown-submenu-2">
                                            <Link className="dropdown-item dropdown-toggle d-flex align-items-center justify-content-between" to="#">
                                                <span>{t('submenu.saes_consulta')}</span>
                                                <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-submenu" />
                                            </Link>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="https://formsweb.navesoft.com/ords_halocol_croma/csavchile.web_consulta_archivos" rel="noreferrer noopener" target="_blank">{t('submenu.saes_consulta_hapag')}</a></li>
                                                <li><a className="dropdown-item" href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER?PSCREENNAME=Documentar mi embarque" rel="noreferrer noopener" target="_blank">{t('submenu.saes_consulta_evergreen')}</a></li>
                                            </ul>
                                        </li>*/}
                                    </ul>
                                </li>
                                <li className="dropdown-submenu">
                                    <Link className="dropdown-item dropdown-toggle d-flex align-items-center justify-content-between" to="#">
                                        <span>{t('menu.transaccion_fletes')}</span>
                                        <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-submenu" />
                                    </Link>
                                    <ul className="dropdown-menu">
                                        <li><NavLink className="dropdown-item" to="/contactenos?origen=FLETES">{t('submenu.fletes_nyk')}</NavLink></li>
                                    </ul>
                                </li>
                                <li className="dropdown-submenu">
                                    <Link className="dropdown-item dropdown-toggle d-flex align-items-center justify-content-between" to="#">
                                        <span>{t('menu.transaccion_cargos')}</span>
                                        <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-submenu" />
                                    </Link>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="https://www.hapag-lloyd.com/es/online-business/quotation/tariffs/local-charges-service-fees.html#anchor_306979" rel="noreferrer noopener" target="_blank">{t('submenu.cargos_hapag')}</a></li>
                                        <li><a className="dropdown-item" href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER?PSCREENNAME=Tarifas" rel="noreferrer noopener" target="_blank">{t('submenu.cargos_evergreen')}</a></li>
                                    </ul>
                                </li>
                                <li><a className="dropdown-item" href="https://www.jotform.com/es/build/240397114928663" rel="noreferrer noopener" target="_blank">{t('submenu.transaccion_sagrilaft')}</a></li>
                            </ul>
                        </li>
                    </ul>
                    <div className="d-flex ">
                        {user ? (
                            <>
                                <button className="btn btn-primary" onClick={openPerfil}>{t('menu.perfil')}</button>
                            </>
                        ) : (
                            <button className="btn btn-primary" onClick={openLogin}>
                                {t('menu.login')}
                            </button>

                        )}

                        {user ? (
                            <>
                                <div className="dropdown ms-4" >
                                    <button ref={dropdownRef} className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ minWidth: '70px' }} >
                                        {language.toUpperCase()}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li><Link className="dropdown-item" to="#" onClick={() => changeLanguage('es')}>{t('menu.spanish')}</Link></li>
                                        <li><Link className="dropdown-item" to="#" onClick={() => changeLanguage('en')}>{t('menu.english')}</Link></li>
                                    </ul>
                                </div>
                                <div className="ms-4 pt-2">
                                    <Tooltip title={<Trans>menu.logout</Trans>} arrow>
                                        <img src={iconoLogout} alt="iconoLogout" className="icon-custom" onClick={handleLogout} />
                                    </Tooltip>
                                </div>
                            </>
                        ) : (
                            <div className="dropdown ms-4" >
                                <button ref={dropdownRef} className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {language.toUpperCase()}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li><Link className="dropdown-item" to="#" onClick={() => changeLanguage('es')}>{t('menu.spanish')}</Link></li>
                                    <li><Link className="dropdown-item" to="#" onClick={() => changeLanguage('en')}>{t('menu.english')}</Link></li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <form id="f_cargue" name="f_cargue" action="" target="_blank" method="post" >
                <input type="hidden" name="LINEAHAPPA" id="LINEAHAPPA" />
                <input type="hidden" name="LINEAEVER" id="LINEAEVER" />
                <input type="hidden" name="LINEANYK" id="LINEANYK" />
                <input type="hidden" name="PORIGEN" id="PORIGEN" value="N" />
                <input type="hidden" name="pdesdenavesoft" id="pdesdenavesoft" value="S" />
            </form>
            <form id="f_pagos" name="f_pagos" action="https://formsweb.navesoft.com/ords_navemar_croma/envio_factura" method="post" >
                <input type="hidden" name="PACCION" id="PACCION" value="M" />
                <input type="hidden" name="PTWEB_NIT" id="PTWEB_NIT" />
                <input type="hidden" name="PTWEB_NITSISTEMA" id="PTWEB_NITSISTEMA" />
            </form>
            <LoginModal isOpen={isLoginOpen} onClose={closeLogin} rutaRedirect="/inicio" />
        </nav>

    );
}

export default Menu;

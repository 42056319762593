import React, { useState, useEffect, lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { AuthProvider, useAuth } from './AuthContext';
import { setCookie, getCookie } from './cookies';
import ReactGA from 'react-ga4';
import CookiePage from './components/CookiePage';
import BeatLoader from 'react-spinners/BeatLoader';

const HomePage = lazy(() => import('./components/pages/HomePage'));
const ContactPage = lazy(() => import('./components/pages/ContactPage'));
const AboutPage = lazy(() => import('./components/pages/AboutPage'));
const NotFoundPage = lazy(() => import('./components/pages/NotFoundPage'));
const PoliticaDatos = lazy(() => import('./components/pages/PoliticaDatos'));
const PoliticaPrevencionLavadoActivos = lazy(() => import('./components/pages/PoliticaPrevencionLavadoActivos'));
const TerminosCondiciones = lazy(() => import('./components/pages/TerminosCondiciones'));
const ResponsabilidadSocialPage = lazy(() => import('./components/pages/ResponsabilidadSocialPage'));
const ServicesPage = lazy(() => import('./components/pages/ServicesPage'));
const AgenciamientoPortuarioPage = lazy(() => import('./components/pages/AgenciamientoPortuarioPage'));
const RepresentacionAerolineasPage = lazy(() => import('./components/pages/RepresentacionAerolineasPage'));
const SolucionesTecnologiaPage = lazy(() => import('./components/pages/SolucionesTecnologiaPage'));
const BPOPage = lazy(() => import('./components/pages/BPOPage'));
const DiversidadPage = lazy(() => import('./components/pages/DiversidadPage'));
const PuertosMaritimosPage = lazy(() => import('./components/pages/PuertosMaritimosPage'));
const NuestrosClientesPage = lazy(() => import('./components/pages/NuestrosClientesPage'));
const PreguntasPage = lazy(() => import('./components/pages/PreguntasPage'));
const TramitesPage = lazy(() => import('./components/pages/TramitesPage'));
const NoticiasFormato2 = lazy(() => import('./components/NoticiasFormato2'));
const NoticiasFormato3 = lazy(() => import('./components/NoticiasFormato3'));
const RegistroPage = lazy(() => import('./components/pages/RegistroPage'));
const PerfilPage = lazy(() => import('./components/pages/PerfilPage'));
const RestablecerPasswordPage = lazy(() => import('./components/pages/RestablecerPasswordPage'));
const RestablecerPasswordInternaPage = lazy(() => import('./components/pages/RestablecerPasswordInternaPage'));
const HeaderRestablecer = lazy(() => import('./components/HeaderRestablecer'));
const LoginInterno = lazy(() => import('./components/pages/LoginInterno'));
const RegistroNavemarPage = lazy(() => import('./components/pages/RegistroNavemarPage'));
const PanelNavemarPage = lazy(() => import('./components/pages/PanelNavemarPage'));
const ArticuloPage = lazy(() => import('./components/pages/ArticuloPage'));
const BlogClientePage = lazy(() => import('./components/pages/BlogClientePage'));
const ItinerariosPage = lazy(() => import('./components/pages/ItinerariosPage'));

function App() {
  const [showModal, setShowModal] = useState(false);

  const AnalyticsTracker = () => {
    const location = useLocation();

    useEffect(() => {
        const excludedRoutes = ['/login-navemar', '/panel-navemar', '/registrese-navemar'];
        
        if (!excludedRoutes.includes(location.pathname)) {
            ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
        }
    }, [location]);

    return null;
};

  useEffect(() => {
    const cookieConsent = getCookie('cookieConsent');
    const localConsent = localStorage.getItem('cookieConsent');
    const excludedRoutes = ['/login-navemar', '/panel-navemar', '/registrese-navemar'];

    if (excludedRoutes.includes(window.location.pathname)) {
      return;
    }

    if (!cookieConsent || !localConsent) {
      setShowModal(true);
    }
  }, []);

  const handleAccept = () => {
    setCookie('cookieConsent', 'true', { expires: 30 });
    localStorage.setItem('cookieConsent', 'true');
    setShowModal(false);
  };

  const handleReject = () => {
    setShowModal(false);
  };

  return (
    <Router>
      <AuthProvider>
        <AnalyticsTracker />
        <div>
          {showModal && <CookiePage onAccept={handleAccept} onReject={handleReject} />}
          {/*Rutas que no deben cargar el menu horizontal del encabezado*/}
          {window.location.pathname === '/restablecer-clave' || window.location.pathname === '/restablecer-clave-interno' || window.location.pathname === '/login-navemar' || window.location.pathname === '/registrese-navemar' || window.location.pathname === '/panel-navemar' ? (
            <HeaderRestablecer />
          ) : (
            <Header />
          )}
          <div className="container-full-width">
            <Suspense fallback={<div className="d-flex justify-content-center align-items-center min-vh-100"><BeatLoader color="#0082c6" size={15} margin={5} /></div>}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/inicio" element={<HomePage />} />
                <Route path="/contactenos" element={<ContactPage />} />
                <Route exact path="/quienes-somos" element={<AboutPage />} />
                <Route path="/responsabilidad-social-y-empresarial" element={<ResponsabilidadSocialPage />} />
                <Route path="/diversidad-equidad-de-genero-e-inclusion" element={<DiversidadPage />} />
                <Route path="/politica-de-tratamiento-de-datos-personales" element={<PoliticaDatos />} />
                <Route path="/manual-de-prevencion-de-lavado-de-activos-y-financiacion-del-terrorismo" element={<PoliticaPrevencionLavadoActivos />} />
                <Route path="/terminos-y-condiciones" element={<TerminosCondiciones />} />
                <Route path="/nuestros-servicios" element={<ServicesPage />} />
                <Route path="/agenciamiento-maritimo-comercial-y-portuario" element={<AgenciamientoPortuarioPage />} />
                <Route path="/puertos-maritimos" element={<PuertosMaritimosPage />} />
                <Route path="/representacion-de-aerolineas" element={<RepresentacionAerolineasPage />} />
                <Route path="/soluciones-de-tecnologia" element={<SolucionesTecnologiaPage />} />
                <Route path="/gestion-de-liberacion-facturacion-y-recaudo-de-gastos-locales" element={<BPOPage />} />
                <Route path="/nuestros-clientes" element={<NuestrosClientesPage />} />
                <Route path="/preguntas-frecuentes" element={<PreguntasPage />} />
                <Route path="/tramites-y-consultas" element={<TramitesPage />} />
                <Route path="/buque-de-evergreen-atraca-en-el-puerto-de-barranquilla" element={<NoticiasFormato2 />} />
                <Route path="/hapag-lloyd-inicia-operaciones-en-turbo" element={<NoticiasFormato3 />} />
                <Route path="/registrese" element={<RegistroPage />} />
                <Route path="/restablecer-clave" element={<RestablecerPasswordPage />} />
                <Route path="/login-navemar" element={<LoginInterno />} />
                <Route path="/registrese-navemar" element={<RegistroNavemarPage />} />
                <Route path="/blog" element={<BlogClientePage />} />
                <Route path="/itinerario-nyk" element={<ItinerariosPage />} />
                <Route path="/perfil" element={<ProtectedRoute><PerfilPage /></ProtectedRoute>} />
                <Route path="/panel-navemar" element={<ProtectedRouteInternal><PanelNavemarPage /></ProtectedRouteInternal>} />
                <Route path="/restablecer-clave-interno" element={<RestablecerPasswordInternaPage />} />
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/error" element={<NotFoundPage />} />
                <Route path="/:titulo" element={<ArticuloPage />} />
              </Routes>
            </Suspense>
          </div>
          {/*Rutas que no deben cargar el menu horizontal del pie de pagina*/}
          {window.location.pathname === '/restablecer-clave' || window.location.pathname === '/restablecer-clave-interno' || window.location.pathname === '/login-navemar' || window.location.pathname === '/registrese-navemar' || window.location.pathname === '/panel-navemar' ? (
            ""
          ) : (
            <Footer />
          )}
        </div>
      </AuthProvider>
    </Router>
  );
}

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/" />;
  }
  return children;
};

const ProtectedRouteInternal = ({ children }) => {
  const { internalUser } = useAuth();
  if (!internalUser) {
    return <Navigate to="/login-navemar" />;
  }
  return children;
};

export default App;

import React from 'react';
import './styles/VerticalButton.css';

const VerticalButton = () => {

    const handleClick = () => {
        window.open('https://navesoftonline.com/', '_blank', 'noopener,noreferrer');
    };
    return (
        <div className="vertical-button">
            <button className="vertical-button-content" onClick={handleClick}>Navesoft</button>
        </div>
    );
};

export default VerticalButton;

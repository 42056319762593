/**
 * Configuración de la aplicación.
 * @module config
 */

/**
 * Objeto de configuración que contiene la URL del endpoint de la API.
 * @typedef {Object} Config
 * @property {string} apiUrl - URL del endpoint de la API.
 */

/**
 * Configuración de la aplicación.
 * @type {Config}
 * @constant
 */
const urlWeb = "https://navemar.com";
const urlWebLogic = "https://formsweb.navesoft.com/ords_navemar_croma";
const urlWebLogicEco = "https://formsweb.navesoft.com/ords_ecocol_croma";
const urlWebLogicHapag = "https://formsweb.navesoft.com/ords_halocol_croma";
const config = {
  apiUrl: `${urlWeb}/api`,
  apiUrlInterno: `${urlWeb}/api/interno`,
  urlRut: urlWeb,
  urlImagenes: urlWeb,
  urlItinerario: urlWeb,
  carguePoderes: `${urlWebLogicHapag}/EDOCUMENT.WEB_LOGIN_PRINCIPAL`,
  consultaPoderes: `${urlWebLogicHapag}/EDOCUMENT.WEB_LOGIN_SEARSHCLI`,
  buquesHapag: `${urlWebLogicHapag}/WEB_BUQUES_V2`,
  buquesEver: `${urlWebLogicEco}/WEB_BUQUES_V2`,
  buquesNyk: `${urlWebLogic}/WEB_BUQUES_V2`,
  secret: 'fcDU5vZCFj2F[7f?(BYQtYY)U5vRe8zL',
  fletesNyk: `${urlWebLogic}/CERT_WEB_FLE`,
  estadoNyk: `${urlWebLogic}/ESTADO_CUENTA_WEB`,
  facturasNyk: `${urlWebLogic}/ENVIO_BL_FAC`,
  mailMasivo: `${urlWebLogic}/WEB_MAIL_MASIVO_CARGUE`,
  instagramLink: "https://www.instagram.com/navemar.colombia/?igsh=MXMycXlmMGk0MHB6dQ%3D%3D",
  facebookLink: "https://www.facebook.com/navemarcolombia/",
  linkedinLink: "https://www.linkedin.com/company/navemar/",
};

export default config;
